import {makeStyles} from '@material-ui/core/styles';

interface Props {
    scrolling: boolean
}

const image = {
    gridRow: '1 / 2',
    gridColumn: '1/ 2',
    maxWidth: '50%',
    maxHeight: '80%',
};

const useStyles = makeStyles((theme: any) =>
    ({
    root: {
        animationDuration: '2s',
        animationTimingFunction: 'linear',
        width: 150,
        height: 150,
        display: 'grid',
        justifyItems: 'center',
        alignItems: 'center',
        margin: 'auto',
        borderRadius: '100%',
        backgroundColor: '#fff',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0 0 20px -4px',
        '@media (max-width: 500px)': {
            width: '120px',
            height: '120px',
        },
        "&:hover .mainImage": {
            animationTimingFunction: 'linear',
            opacity: 0,
        },
        "&:hover .flipImage": {
            animationTimingFunction: 'linear',
            opacity: 1,
        }
    },
    flipImage: (props: Props) => {
        const style = {
            ...image,
            opacity: 0,
        };
        if (props.scrolling) {
            style.opacity = 1;
        }
        return style;
    },
    mainImage: (props: Props) => {
        const style = {
            ...image,
            opacity: 1,
        };
        if (props.scrolling) {
            style.opacity = 0;
        }
        return style;
    },
    }));

export default useStyles;
