import {ViewsActionInterface, ViewsActionTypes} from "./views.actions";

const storeInit = {
    subpage: null,
    pageTitle:'Webiks',
    coverImage:null,
    coverTitle:null,
    coverSubTitle:null
};

const viewsReducer = (state = storeInit, action:ViewsActionInterface) => {
    switch (action.type) {
        case ViewsActionTypes.setPageDetails:
            return{
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default viewsReducer;
