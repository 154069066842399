import React from 'react';
import CSS from 'csstype';

function DataVisualization() {

    const imgStyle: CSS.Properties = {
        maxWidth: '100%',
        width: '925px',
        margin: '50px 0',
    };

    return (
        <div className={'ServicesDescriptionPage-content'}>
            <h1>Combating information overload</h1>
            <p>“There is no such thing as information overload. There is only bad design,” said Prof. Edward Tufte. Most of our apps are heavy on data in all its forms; and we want our users to be able to extract the relevant insights and conclusions from the data. As users’ ability to process such a great amount of data from its text or tabular or numerical forms is limited, we always look for creative ways to reduce text and numeric data view to the absolute minimum required and convey information through meaningful, useful, interactive data visualizations.</p>
            <h1>Bring data to life with the right visualization</h1>
            <p>Data visualizations are not just more “easy on the eyes,” they enable users to discover traits hidden in the data and to better explain the data and trends to others through appealing visual representations. Network visualizations, heatmaps, timelines, spectrograms, clustering trees, multi-dimensional graphs – just to name a few visualizations – when crafted carefully can be extremely useful and meaningful.

                With the rise of numerous great open-source visualization libraries, such as D3, Chart.js, Kibana and Dygraphs, data visualization possibilities are practically endless. Our development team specializes in making our wildest visualization ideas come true. If you can dream it, we can make it.</p>
            <img src={process.env.PUBLIC_URL + `/assets/images/services/dataVisualization/dataVisualization.gif`} style={imgStyle} alt="DataVisualization"/>
        </div>
    );
}

export default DataVisualization;
