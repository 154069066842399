import React from 'react';
import './TeamPage.css';
import {setPageDetails} from "../../store/views.actions";
import {useDispatch} from "react-redux";
import teamMembers from "../../assets/HR/team.json";
import MemberCard, {TeamMember} from "./components/memberCard/memberCard";

function TeamPage() {
    const dispatch = useDispatch();
    const pageDetails = {
        pageTitle: 'Team',
        coverImage: 'cover-team',
        coverTitle: 'Webiks is the people of Webiks',
        coverSubTitle: null
    };
    dispatch(setPageDetails(pageDetails));
    return (
        <div className={'Page-TeamPage'}>
            <h1 className={'Page-TeamPage-title'}>Our Team</h1>
            <div className={'Page-TeamPage-members'}>
                {
                    teamMembers.map((member: TeamMember, key) => {
                        return (
                            <MemberCard {...member} key={key}/>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default TeamPage;
