import React from 'react';
import './ValuesPage.css';
import values from "../../assets/general/values.json";
import {setPageDetails} from "../../store/views.actions";
import {useDispatch} from "react-redux";

function ValuesPage() {
    const dispatch = useDispatch();
    const pageDetails ={
        pageTitle:'Our Values',
        coverImage:'cover-values',
        coverTitle:'Our Core Values',
        coverSubTitle:null
    };
    dispatch(setPageDetails(pageDetails));
    return (
        <div className={'Page-ValuesPage'}>
            {
                values.map((value,key) => {
                    return (
                        <div className={'Pages-ValuesPage-value'} key={key}>
                            <div className={'Pages-ValuesPage-number'}>{key+1}</div>
                            <div className={'Pages-ValuesPage-title'}>{value.value}</div>
                            <div className={'Pages-ValuesPage-content'}>{value.description}</div>
                        </div>
                    );
                })
            }
        </div>
    );
}

export default ValuesPage;
