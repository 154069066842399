import React from 'react';
import './CareersPage.css';
import positions from "../../assets/HR/positions.json";
import {useDispatch} from 'react-redux';
import {setPageDetails} from "../../store/views.actions";
import {PagesEnum} from "../../definitions/pages-enum";
import {useHistory} from "react-router-dom";

function CareersPage() {
    const history = useHistory();
    const dispatch = useDispatch();

    const pageDetails ={
        pageTitle:'Careers',
        coverImage:'cover-careers',
        coverTitle:'Work at Webiks',
        coverSubTitle:null
    };
    dispatch(setPageDetails(pageDetails));
    return (
        <div className={'Page-CareersPage'}>
            <h1 className={'Page-CareersPage-pageTitle'}>Open positions</h1>
            {
                positions.map((position, key) => {
                    return (
                        <div className={'Page-CareersPage-position'}
                             onClick={() => {history.push(`${PagesEnum.JOB_DESCRIPTION}/${position.id}`)}}
                             key={key}>
                            <h3 className={'Page-CareersPage-title'}>{position.title}</h3>
                            <h4 className={'Page-CareersPage-applyButton'}>APPLY NOW</h4>
                        </div>
                    );
                })
            }
        </div>
    );
}

export default CareersPage;
