import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import './NavBar.css';
import {PagesEnum} from "../../definitions/pages-enum";
import Logo from "../Logo/Logo";
import menuOpenIcon from "../../assets/images/hamburgerOpen.svg";
import menuCloseIcon from "../../assets/images/hamburgerClose.svg";

export const navBarPages = [
    {title: 'Services', page: PagesEnum.SERVICES},
    {title: 'Team', page: PagesEnum.TEAM},
    {title: 'Our Core Values', page: PagesEnum.VALUES},
    {title: 'Careers', page: PagesEnum.CAREER},
    {title: 'Contact Us', page: PagesEnum.CONTACT},
    // {title: 'Blog', link: 'https://blog.webiks.com/'}
];

export const useWindowEvent = (event:string, callback:any) => {
    useEffect(() => {
        window.addEventListener(event, callback);
        return () => window.removeEventListener(event, callback);
    }, [event, callback]);
};

function NavBar() {
    const location = useLocation();
    const history = useHistory();
    const [menuOpen, setMenuOpen] = useState(false);
    const currentPage = location.pathname.substr(1);

    const closeMenuMethod = () => {
      if(menuOpen) setTimeout(()=>setMenuOpen(false),50);
    };

    useWindowEvent("mouseup",closeMenuMethod);
    useWindowEvent("touchend",closeMenuMethod);

    const menuOpenClass = menuOpen ? 'NavBar-menuOpen' : '';
    const HamburgerOpenClass = menuOpen ? 'NavBar-hamburgerOpen' : '';
    return (
        <header className={'NavBar'}>
            <div className={'NavBar-background'}>
                <Logo/>
                <div className={`NavBar-hamburger ${HamburgerOpenClass}`}
                     onClick={() => {setMenuOpen(!menuOpen)}}
                >
                    <img src={menuOpenIcon} alt={'menu'}/>
                    <img src={menuCloseIcon} alt={'menu'}/>
                </div>

                <div className={`NavBar-items ${menuOpenClass}`}>
                    {
                        navBarPages.map((page, key) => {
                                const isCurrentPage = currentPage === page.page ? 'NavBar-selected' : '';
                                const clickAction = page.page ?
                                    () => {history.push(`/${page.page}`)} :
                                    // @ts-ignore
                                    () => {window.open(page.link) };
                                return (
                                    <button className={'NavBar-link ' + isCurrentPage}
                                            key={key}
                                            onClick={clickAction}
                                    >{page.title}</button>
                                );
                            }
                        )}
                </div>
            </div>
        </header>
    );
}

export default NavBar;
