import {makeStyles} from '@material-ui/core/styles';

interface Props{
    extraFiledExists: boolean;
}

const useStyles = makeStyles((theme: any) =>({
    root: {
        marginBottom: 36,
        display:'grid',
    },
    name: (props: Props) => ({
        margin: props.extraFiledExists ? 0 : '30px 0 0 0',
        '@media (max-width: 500px)': {
            fontSize: 18,
        },
    }),
    title:{
        margin: '0 0 20px 0',
        '@media (max-width: 500px)': {
            fontSize: 14,
        },
    },
    extraField:{
        paddingTop: 8,
        height: 22,
        color: '#8B8B8B',
        fontSize: 16,
    },
    socialLink:{
        width: 25,
        height: 25,
        margin: 10,
        cursor: 'pointer',
        '@media (max-width: 500px)': {
            width: 19,
            height: 19,
        },
    }
}));

export default useStyles;
