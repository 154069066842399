import React from 'react';
import CSS from 'csstype';

function DataScience() {

    const imgStyle: CSS.Properties = {
        maxWidth: '100%',
        width: '925px',
        margin: '50px 0',
    };

    return (
        <div className={'ServicesDescriptionPage-content'}>
            <h1>Distilling the oil of data</h1>
            <p>“Data is the new oil,” said Clive Humby. Recent advances in machine and deep learning technologies have changed the entire landscape. Today’s machine learning models can process and distill the crude oil of data better than ever before.
                By repeatedly providing innovative, cost-effective, and differentiated solutions to complicated tasks, at Webiks, we enable our clients to break barriers and harness the potential of modern machine learning techniques.</p>
            <h1>An end-to-end data science stack</h1>
            <p>
                Our data science and machine learning services include:
                <ul>
                    <li>Training and evaluating deep learning models, mainly computer vision deep neural networks for satellite and aerial imagery understanding</li>
                    <li>Unsupervised learning for anomaly detection from structured data, mainly time series data</li>
                    <li>Data labeling — from satellite and aerial imagery annotation to data pre-processing via custom scripts — all to create data sets for ML model training and evaluation</li>
                    <li>Product design and development of innovative, AI-powered man-machine interfaces</li>
                    <li>Custom-made Kaggle style data science competitions, such as the <a href={'https://mafatchallenge.mod.gov.il/'}> MAFAT Challenge</a></li>
                </ul>
            </p>
            <picture>
                <source type="image/webp" srcSet={process.env.PUBLIC_URL + `/assets/images/services/dataScience/dataScience.webp`}/>
                <img src={process.env.PUBLIC_URL + `/assets/images/services/dataScience/dataScience.jpg`} style={imgStyle} alt="Data Science"/>
            </picture>
        </div>
    );
}

export default DataScience;
