import {createTheme} from "@material-ui/core";

export const themeStyle =  {
    colors: {
        main: '#994076',
        var1: '#bd5a96',
        var2: '#792d5b',
        secondary: '#6cc2ae',
        secondaryVar1: 'rgba(111,193,194,0.1)',
        mainText: '#2c2b2c',
        secondaryText: '#616161',
        brightText: '#ffffff',
        mainTitle: '#273152',
        mainBackground: '#F8F7FF',
        secondaryBackground: '#FFF',
        gradientBackground: 'linear-gradient(180deg, #fff 8%, #f8f7ff 16%)',
        mainBorder: '#d7d8ea',
        secondaryBorder: '#994076',
    },
    font:{
        family:'Muli, sans-serif',
        strongWeight: 700,
        mainWeight: 400,
        secondaryWeight: 200,
        largeTitleSize:50,
        mediumTitleSize:43,
        titleSize:38,
        subtitleSize:24,
        largeSize:19,
        size:15,
    }
};

// @ts-ignore
const theme = createTheme(themeStyle);

export default theme;
