export enum PagesEnum {
    HOME = '/',
    SERVICES = 'services',
    TEAM = 'team',
    VALUES = 'values',
    CAREER = 'careers',
    CONTACT = 'contact',
    BLOG = 'blog',
    JOB_DESCRIPTION = 'career',
    SERVICE_DESCRIPTION = 'service'
}
