import React from 'react';
import './SocialBar.css';
import linkedin from "../../../../assets/images/linkedin.png";
import twitter from "../../../../assets/images/twitter.png";
import github from "../../../../assets/images/github.png";

function SocialBar() {
    return (
        <div className={'SocialBar'}>
            <img className={'SocialBar-link'} src={linkedin} alt={'linkedin logo'} onClick={() => {window.open('https://www.linkedin.com/company/webiks/')}}/>
            <img className={'SocialBar-link'} src={twitter} alt={'twitter logo'} onClick={() => {window.open('https://twitter.com/therealwebiks')}}/>
            <img className={'SocialBar-link'} src={github} alt={'github logo'} onClick={() => {window.open('https://github.com/Webiks')}}/>
        </div>
    );
}

export default SocialBar;
