import React from 'react';
import CSS from 'csstype';

function Training() {

    const imgStyle: CSS.Properties = {
        maxWidth: '100%',
        width: '925px',
    };

    return (
        <div className={'ServicesDescriptionPage-content'}>
            <h1>Keeping pace with technology</h1>
            <p>
                The pace of innovation in today’s technological atmosphere is astonishing. The software world has moved from a closed-source dictatorship to an open-source democracy, with innovation being the fuel of this democracy — databases, frameworks, libraries, standards, and best practices rise and fall faster than ever before.<br/>
                The greatest challenge in the software age is that of training. How do you keep your R&D teams up to date? How do you help your engineers keep pace with the technology change?
            </p>
            <picture>
                <source type="image/webp" srcSet={process.env.PUBLIC_URL + `/assets/images/services/Training/WEBBEAST2019.webp`}/>
                <img src={process.env.PUBLIC_URL + `/assets/images/services/Training/WEBBEAST2019.jpg`} style={imgStyle} alt="Training"/>
            </picture>
            <h1>Every beauty needs a beast</h1>
            <p>
                For Webiks, keeping pace with technology is our livelihood. We have, therefore, developed WEBbeast, a training program for the full-stack web developer wishing to master the latest web technologies and build high performing complex web apps. The program was first built for internal use, but we have decided to open it and offer it to our clients, enhancing the value Webiks adds to its clients.
                <br/><br/>
                Angular, React, RxJS, Redux, NodeJS, MongoDB, Microservices, Docker Container, CI/CD, and many more — our instructors are ready to help your R&D team keep pace with technology and prepare for large-scale, modern software projects.
                <br/><br/>
                WEBbeast is offered in three models:<br/>
                1. A course tailor-made for the client’s requirements at a client’s site of choice.<br/>
                2. A public 6-month-long program, see <a href={'https://webbeast.co.il/'}>WEBbeast website</a> (Hebrew only).<br/>
                3. All of WEBbeast’s content is open source! Feel free to use it your way!
            </p>
        </div>
    );
}

export default Training;
