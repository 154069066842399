import React from 'react';
import './ServiceBox.css';
import {PagesEnum} from "../../../definitions/pages-enum";
import {useHistory} from "react-router-dom";

interface IProps {
    img: string
    title: string
    link?: number | string
    content: string
}

const ServiceBox: React.FC<IProps> = ({img, title, link, content}) => {
    const history = useHistory();
    const clickAction = typeof link === 'undefined' ? () => {} : () => {history.push(`${PagesEnum.SERVICE_DESCRIPTION}/${link}`)};

    return (
        <div className={'Page-ServicesPage-ServiceBox'} onClick={clickAction}>
            <img className={'Page-ServicesPage-ServiceBox-img'}
                 src={`${process.env.PUBLIC_URL}/assets/images/graphics/${img}`}
                 alt="small graphic"/>
            <h1 className={'Page-ServicesPage-ServiceBox-title'}>{title}</h1>
            <p className={'Page-ServicesPage-ServiceBox-content'}>{content}</p>
            <h3 className={'Page-ServicesPage-ServiceBox-readMore'}>READ MORE</h3>
        </div>
    );
};

export default ServiceBox;
