import React from 'react';
import {setPageDetails} from "../../store/views.actions";
import {useDispatch} from "react-redux";
import Services from "./components/Services";

function ServicesPage() {
    const dispatch = useDispatch();
    const pageDetails ={
        pageTitle:'Services',
        coverImage:'cover-services',
        coverTitle:'We only do what we do best',
        coverSubTitle:'build powerful data-driven intelligent apps.'
    };
    dispatch(setPageDetails(pageDetails));
    return (
        <Services/>
    );
}

export default ServicesPage;
