import React from 'react';
import useStyles from "./memberCard.css";
import LogoBox from "../logoBox/LogoBox";
import githubLogo from "../../../../assets/images/github.png";
import linkedinLogo from "../../../../assets/images/linkedin.png";
import twitterLogo from "../../../../assets/images/twitter.png";

export interface TeamMember {
    name: string
    title: string
    extra?: string
    github?: string
    linkedin?: string
    twitter?: string
    mainImage: string
    flipImage: string
}

const MemberCard: React.FC<TeamMember> = ({name, title,extra, github, linkedin, twitter, mainImage, flipImage}) => {
    const classes = useStyles({extraFiledExists: !!extra});
    return (
        <div className={classes.root}>
            <LogoBox name={name} mainImage={mainImage} flipImage={flipImage}/>
            {
                !!extra && <div className={classes.extraField}>{extra}</div>
            }
            <h2 className={classes.name}>{name}</h2>
            <h3 className={classes.title}>{title}</h3>
            <div>
                {github &&
                <img className={classes.socialLink} src={githubLogo} alt={'github logo'}
                     onClick={() => {
                         window.open(github)
                     }}/>}
                {linkedin &&
                <img className={classes.socialLink} src={linkedinLogo} alt={'linkedin logo'}
                     onClick={() => {
                         window.open(linkedin)
                     }}/>}
                {twitter &&
                <img className={classes.socialLink} src={twitterLogo} alt={'linkedin logo'}
                     onClick={() => {
                         window.open(twitter)
                     }}/>}
                {!twitter && !linkedin && !github &&
                <div className={classes.socialLink}/>
                }
            </div>
        </div>
    );
};

export default MemberCard;
