import React from 'react';
import {setPageDetails} from "../../store/views.actions";
import {useDispatch} from "react-redux";
import './NotFound.css';

function NotFound() {
    const dispatch = useDispatch();
    const pageDetails ={
        pageTitle:'You are Lost',
        coverImage:null,
        coverTitle:null,
        coverSubTitle:null
    };
    dispatch(setPageDetails(pageDetails));
    return (
        <div className={'Page-NotFound'}>
            {
                <img className={'pageNotFound'} src={process.env.PUBLIC_URL + `/assets/images/graphics/404.png`} alt={'page not found'}/>
            }
        </div>
    );
}

export default NotFound;
