import React from 'react';
import {useSelector} from "react-redux";
import './Cover.css';
import {CSSTransition, TransitionGroup} from "react-transition-group";

function Cover() {
    const {pageTitle, coverImage, coverTitle, coverSubTitle} = useSelector((state: any) => state.views);

    document.title = pageTitle;
    const classes = coverImage ? 'Cover' : '';

    return (
        <div className={classes}>
            {
                coverImage &&
                <TransitionGroup className="Cover-image-group">
                    <CSSTransition
                        key={coverImage}
                        timeout={600}
                        classNames="fade">

                        <picture className="Cover-image">
                            <source type="image/webp" media="(min-width: 650px)"
                                    srcSet={process.env.PUBLIC_URL + `/assets/images/coverImages/${coverImage}.webp`}/>
                            <source type="image/webp"
                                    srcSet={process.env.PUBLIC_URL + `/assets/images/coverImages/${coverImage}650.webp`}/>
                            <img className="Cover-image"
                                 src={process.env.PUBLIC_URL + `/assets/images/coverImages/${coverImage}.jpg`}
                                 alt={pageTitle}/>
                        </picture>
                    </CSSTransition>
                </TransitionGroup>
            }


            {
                coverImage && coverTitle && <h1 className={'Cover-title'}>{coverTitle}</h1>
            }{
            coverImage && coverSubTitle && <h2 className={'Cover-subTitle'}>{coverSubTitle}</h2>
            }
        </div>
    );
}

export default Cover;
