import React from 'react';
import './WhyUsBox.css';

declare module "react" {
    interface ImgHTMLAttributes<T> extends HTMLAttributes<T> {
        loading?: "auto" | "eager" | "lazy";
    }
}

interface IProps {
    img:string
    title:string
    content:string
}

const WhyUsBox:React.FC<IProps> = ({img,title,content})=> {

    return (
        <div className={'Page-HomePage-WhyUsBox'}>
            <picture className={'Page-HomePage-WhyUsBox-img'} >
                <source type="image/webp" srcSet={`${process.env.PUBLIC_URL}/assets/images/graphics/${img}.webp`}/>
                <img className={'Page-HomePage-WhyUsBox-img'} src={`${process.env.PUBLIC_URL}/assets/images/graphics/${img}.jpg`} alt={title} loading="lazy"/>
            </picture>
            <h1 className={'Page-HomePage-WhyUsBox-title'}>{title}</h1>
            <p className={'Page-HomePage-WhyUsBox-content'} dangerouslySetInnerHTML={{__html:content}}/>
        </div>
    );
};

export default WhyUsBox;
