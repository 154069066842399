import React from 'react';
import CSS from 'csstype';

function Consulting() {

    const imgStyle: CSS.Properties = {
        maxWidth: '100%',
        width: '925px',
        margin: '50px 0',
    };

    return (
        <div className={'ServicesDescriptionPage-content'}>
            <p>
                We have built complex, data-rich, high-performing web applications.<br/>
                We have helped clients transform their infrastructure from legacy technology to modern architecture that leverages state-of-the-art technologies, such as cloud computing, Docker containers, micro-services, and complex web apps.<br/>
                We have integrated geospatial apps and analysis pipelines with existing non-geo software-stacks of our clients.<br/>
                We have designed beautiful, data-driven applications and walked multi-disciplinary R&D teams through the entire product lifecycle.
            </p>

            <picture>
                <source type="image/webp" srcSet={process.env.PUBLIC_URL + `/assets/images/services/consulting/consulting.webp`}/>
                <img src={process.env.PUBLIC_URL + `/assets/images/services/consulting/consulting.jpg`} style={imgStyle} alt="Consulting"/>
            </picture>
            <p>
                As a result, we have the required experience, knowledge, and patience to consult R&D and product teams that face difficult challenges, such as framework migration, performance optimization, large-scale web architecture, geospatial sense-making, product pivoting, and many more.<br/>
                Our consultants can share their knowledge and provide you with a non-linear progression, saving your team hundreds of hours.
            </p>
        </div>
    );
}

export default Consulting;
