import React from 'react';
import './ServicesDescriptionPage.css';
import {setPageDetails} from "../../store/views.actions";
import {useDispatch} from "react-redux";
import {ServicesEnum} from "../../definitions/services-enum";
import {PagesEnum} from "../../definitions/pages-enum";
import ComplexWebApplications from "./pages/ComplexWebApplications";
import Geospatial from "./pages/Geospatial";
import ProductDesign from "./pages/ProductDesign";
import DataVisualization from "./pages/DataVisualization";
import DataScience from "./pages/DataScience";
import Consulting from "./pages/Consulting";
import Training from "./pages/Training";
import {useHistory, useParams} from "react-router-dom";

function ServicesDescriptionPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const {serviceId} = useParams();

    //if service page was removed or an illegal sub path was entered
    //will reroute to services page
    if(!serviceId){
        history.push(`/${PagesEnum.SERVICES}`);
        return(<div/>);
    }

    const pageDetails = {
        pageTitle: `Services - ${serviceId}`,
        coverImage: null,
        coverTitle: null,
        coverSubTitle: null
    };
    dispatch(setPageDetails(pageDetails));
    return (
        <div className={'ServicesDescriptionPage'}>
            <div className={'ServicesDescriptionPage-back'}>
                <h3 className={'ServicesDescriptionPage-backLink'}
                    onClick={() => {history.push(`/${PagesEnum.SERVICES}`)}}>
                    {'< Services'}
                </h3>
                <h2 className={'ServicesDescriptionPage-backTitle'}>{serviceId}</h2>
            </div>
            {serviceId === ServicesEnum.COMPLEX_WEB_APPLICATIONS && <ComplexWebApplications/>}
            {serviceId === ServicesEnum.GEOSPATIAL && <Geospatial/>}
            {serviceId === ServicesEnum.PRODUCT_DESIGN && <ProductDesign/>}
            {serviceId === ServicesEnum.DATA_VISUALIZATION && <DataVisualization/>}
            {serviceId === ServicesEnum.DATA_SCIENCE && <DataScience/>}
            {serviceId === ServicesEnum.CONSULTING && <Consulting/>}
            {serviceId === ServicesEnum.TRAINING && <Training/>}
        </div>
    );
}

export default ServicesDescriptionPage;
