import React from 'react';
import {setPageDetails} from "../../store/views.actions";
import {useDispatch} from "react-redux";

function ContactPage() {
    const dispatch = useDispatch();
    const pageDetails ={
        pageTitle:'Contact Us',
        coverImage:'cover-contact',
        coverTitle:'Contact Us',
        coverSubTitle:null
    };
    dispatch(setPageDetails(pageDetails));
    return (
        <div className={'Page-ContactPage'}>
            <iframe src={'https://pipedrivewebforms.com/form/869d76b74897877d3d588c42d341ac412230070'}
                    width="100%"
                    height="650"
                    frameBorder="0"
                    scrolling="no"
                    title="contact"
            />
        </div>
    );
}

export default ContactPage;
