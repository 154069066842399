import React from 'react';
import CSS from 'csstype';
import SimpleImageSlider from "react-simple-image-slider";

function Geospatial() {

    const images = [
        { url: `${process.env.PUBLIC_URL}/assets/images/services/geospatial/ansyn1.png`},
        { url: `${process.env.PUBLIC_URL}/assets/images/services/geospatial/ansyn2.png` },
        { url: `${process.env.PUBLIC_URL}/assets/images/services/geospatial/ansyn3.png` },
        { url: `${process.env.PUBLIC_URL}/assets/images/services/geospatial/ansyn4.png` },
    ];

    const sliderWidth = window.innerWidth >1100 ? 925 : window.innerWidth*0.85;
    const sliderHeight = sliderWidth * 0.6;
    const imgStyle: CSS.Properties = {
        width: `${sliderWidth}px`,
        margin: '50px auto',
    };

    return (
        <div className={'ServicesDescriptionPage-content'}>
            <h1>Geography is everywhere</h1>
            <p>“Without geography, you’re nowhere!” said Jimmy Buffet, and indeed, location-based data is now everywhere. Every business, startup, or enterprise will find it useful to collect, analyze, and visualize location-based data. Every vertical can benefit from geospatial data exploitation, be it drones, autonomous vehicles, defense, agtech, construction, etc.
                We build geospatial platforms and applications using open source technology. Our custom-made geospatial solutions help our clients gain location-based insights.</p>
            <h1>Multi-layer GIS</h1>
            <p>A typical Webiks geospatial project consists of four layers:
                <ol>
                    <li>Database to store the geospatial data. We mostly use PostgreSQLWith PostGIS plugin.</li>
                    <li>Data analysis layer. Ranging from simple geospatial calculations to complex computer vision deep neural networks and time series unsupervised anomaly detection, we tailor the data analysis suite to the client’s use case.</li>
                    <li>Geospatial server that serves vector and raster data via standard protocols. We mostly use GeoServer, Google Earth Enterprise, or hosted services, such as Cesium ion.</li>
                    <li>Geo-enhanced complex web app. We enrich the custom-made web apps with rich geographic visualization and interactions. For 2D maps, we use OpenLayers, Leaflet, and Mapbox. For 3D use cases, we use CesiumJS, a 3D virtual globe.</li>
                </ol>
            </p>
            <div style={imgStyle}>
                <SimpleImageSlider
                    width={sliderWidth}
                    height={sliderHeight}
                    images={images}
                />
            </div>
            <h1>Geospatial inside</h1>
            <p>Our geospatial expertise allows Webiks to build geospatial web applications and data analytics pipelines that enable our clients to answer complex questions across a wide range of domains: drone mission planning, autonomous vehicle testing, crop yield estimation, and overhead imagery analysis, to name a few. With Webiks in the loop, you don’t have to be a GIS specialist to harness the potential of location data!</p>
        </div>
    );
}

export default Geospatial;
