import React from 'react';
import CSS from 'csstype';

function ComplexWebApplications() {

    const techBoxStyle: CSS.Properties = {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        margin: "70px 24px",
    };

    const techImgStyle: CSS.Properties = {
        maxHeight: '60px',
        maxWidth: '140px',
        width: 'auto',
        height: 'auto',
    };

    const videoStyle: CSS.Properties = {
        maxWidth: '100%',
        width: '925px',
        margin: '50px 0',
    };

    return (
        <div className={'ServicesDescriptionPage-content'}>
            <h1>Full-stack, full-kit</h1>
            <p>Software is becoming more and more capable — and more and more complex. Modern web applications require, by nature, the orchestration of many different moving parts. Webiks has full-stack expertise in all the relevant dimensions: front end, back end, and DevOps allow us to build high-performing, scalable, secure, human-focused complex web apps.
                The full-stack nature of Webiks allows us to offer our clients a one-stop-shop experience.</p>
            <h1>Front end — powerful single-page applications</h1>
            <p>Unlike static and simple old-fashioned websites, complex web apps are packed full of business logic and data handling. Building a complex web app is all about letting the users interact — interact with the app, interact with the data, interact with each other — in a smooth, fluid, responsive, and engaging fashion.</p>
            <div style={techBoxStyle}>
                <img src={process.env.PUBLIC_URL + `/assets/images/services/complexWebApps/react.svg`} style={techImgStyle} alt="react"/>
                <img src={process.env.PUBLIC_URL + `/assets/images/services/complexWebApps/angular.png`} style={techImgStyle} alt="angular"/>
                <img src={process.env.PUBLIC_URL + `/assets/images/services/complexWebApps/mongodb.png`} style={techImgStyle} alt="mongoDB"/>
                <img src={process.env.PUBLIC_URL + `/assets/images/services/complexWebApps/graphQL.png`} style={techImgStyle} alt="GraphQL"/>
                <img src={process.env.PUBLIC_URL + `/assets/images/services/complexWebApps/node.png`} style={techImgStyle} alt="Node"/>
                <img src={process.env.PUBLIC_URL + `/assets/images/services/complexWebApps/redis.png`} style={techImgStyle} alt="redis"/>
                <img src={process.env.PUBLIC_URL + `/assets/images/services/complexWebApps/elastic.png`} style={techImgStyle} alt="elastic"/>
            </div>
            <p>We have a strong background in building powerful, complex web apps — apps that perform and look great on every device. We specialize in developing single-page applications with the industry-leading frameworks: React and Angular, two JavaScript frameworks that have been widely adopted in the industry.
                The apps we build handle massive streams of real-time data and query massive databases. We, therefore, master all Flux data management techniques, including RxJS, Redux, NgRx, and more.</p>
            <h1>Back end — high-performing microservices</h1>
            <p>Complex web applications handle massive amounts of data, sophisticated business logic, and complex workflows. Modern, server-side technologies process, store, index, retrieve, and serve data extremely efficiently, accompanying user interaction by serving exactly the right data at the right time.
                The server-side software we build includes databases, APIs, and microservices, all of which are scalable, high-performing, and production-ready. We fear no technology, but we specialize in NodeJS, Python, MongoDB, Elastic, PostgreSQL, Redis, GraphQL, Rest APIs, and WebSockets.</p>
            <video style={videoStyle} autoPlay loop muted>
                <source src={process.env.PUBLIC_URL + `/assets/images/services/complexWebApps/ception.mp4`} type="video/mp4"/>
                <img src={process.env.PUBLIC_URL + `/assets/images/services/complexWebApps/ception.png`} alt="ception" />
            </video>
            <h1>DevOps — orchestrating it all together</h1>
            <p>We have tremendous amount of experience delivering high-performing, sophisticated, data-driven, long-lasting, production-ready, complex web apps. Our apps serve thousands of concurrent users and process tens of thousands of events every second.

                This is achieved with a set of practices commonly referred to as “DevOps” (software development operations). DevOps is all about removing friction from the process of software integration and software deployment, allowing rapid software iterations, even at scale.The microservices we build are containerized and are deployed automatically to modern cloud environments. Our front-end web apps are compiled ahead of time (AOT) to boost performance.  Unit and integration tests are run automatically whenever necessary. Events and actions are logged for monitoring, sense-making, and debriefing. Computing resources are allocated dynamically to maximize performance and reduce costs. Business and technical parameters can be updated in an automated configuration management process.

                We build software for people to use it — and that’s why we deliver not only software but also orchestrate its operations for it to actually meet our clients’ business goals. </p>
        </div>
    );
}

export default ComplexWebApplications;
