import React from 'react';
import './Logo.css';
import logoIcon from "../../assets/images/logo.svg";
import logoText from "../../assets/images/logoTextOnly.svg";
import {PagesEnum} from "../../definitions/pages-enum";
import {useHistory} from "react-router-dom";

function Logo() {
    const history = useHistory();
    return (
        <div className={'Logo'} onClick={()=>{history.push(PagesEnum.HOME)}}>
            <img src={logoIcon} className="Logo-icon" alt="logo"/>
            <img src={logoText} className="Logo-text" alt="webiks"/>
        </div>
    );
}

export default Logo;
