import React from 'react';
import './Mission.css';
import ContactButton from "../../../../components/ContactButton/ContactButton";

function Mission() {

    return (
        <div className={'Page-HomePage-Mission'}>
            <h1 className="Page-HomePage-Mission-heading">Building intelligent apps for visionary clients</h1>
            <p className="Page-HomePage-Mission-paragraph">We create powerful, data-driven applications that help our clients innovate
                and grow. </p>
           <picture className={'Page-HomePage-Mission-image'}>
                <source type="image/webp" media="(min-width: 450px)" srcSet={process.env.PUBLIC_URL + '/assets/images/graphics/hpMain.webp'}/>
                <source type="image/webp" srcSet={process.env.PUBLIC_URL + '/assets/images/graphics/hpMain450.webp'}/>
                <img className={'Page-HomePage-Mission-image'} src={process.env.PUBLIC_URL + '/assets/images/graphics/hpMain.png'} alt={'project mock-up'}/>
            </picture>
            <div  className={'Page-HomePage-Mission-contact'}>
                <ContactButton/>
            </div>
        </div>
    );
}

export default Mission;
