import React from 'react';
import {PagesEnum} from "../definitions/pages-enum";
import ValuesPage from "./Values/ValuesPage";
import CareersPage from "./Careers/CareersPage";
import JobDescriptionPage from "./JobDescription/JobDescriptionPage";
import TeamPage from "./Team/TeamPage";
import ServicesPage from "./Services/ServicesPage";
import HomePage from "./Home/HomePage";
import ContactPage from "./Contact/ContactPage";
import ServicesDescriptionPage from "./ServiceDescription/ServicesDescriptionPage";
import {Route, Switch} from "react-router-dom";
import NotFound from "./NotFound/NotFound";

function Page() {
    const pageRoutes = [
        {path:PagesEnum.SERVICES,component:ServicesPage},
        {path:PagesEnum.TEAM,component:TeamPage},
        {path:PagesEnum.VALUES,component:ValuesPage},
        {path:PagesEnum.CAREER,component:CareersPage},
        {path:PagesEnum.CONTACT,component:ContactPage},
        {path:`${PagesEnum.JOB_DESCRIPTION}/:positionId`,component:JobDescriptionPage},
        {path:`${PagesEnum.SERVICE_DESCRIPTION}/:serviceId`,component:ServicesDescriptionPage},
    ];

    return (
        <div className={'Page'}>
            <Switch>
                <Route exact path={['/','/index.html','/site/']} component={HomePage}/>
                {
                    pageRoutes.map((pageRoute,key)=>{
                        return(
                            <Route
                                key={key}
                                path={[
                                    `/${pageRoute.path}`,
                                    `/${pageRoute.path}.html`
                                ]}
                                component={pageRoute.component}
                            />
                        )
                    })
                }
                <Route
                    path={`*`}
                    component={NotFound}
                />
            </Switch>
        </div>
    );
}

export default Page;
