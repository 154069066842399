import React from 'react';
import {useIsScrolling} from "../../../../hooks/useIsScrolling";
import useStyles from "./logoBox2.css";

interface IProps {
    name: string
    mainImage: string
    flipImage: string
}

const LogoBox: React.FC<IProps> = ({name, mainImage, flipImage}) => {
    const { scrollDirectionY } = useIsScrolling();
    const classes = useStyles({scrolling: scrollDirectionY === 'down'});
    return (
        <div className={classes.root}>
            <img className={`${classes.mainImage} mainImage`}
                 src={process.env.PUBLIC_URL + `/assets/images/TeamMembers/${mainImage}`}
                 alt={name}/>
            <img className={`${classes.flipImage} flipImage`}
                 src={process.env.PUBLIC_URL + `/assets/images/TeamMembers/${flipImage}`}
                 alt={name}/>
        </div>
    );
};

export default LogoBox;
