import React, {useState} from 'react';
import CSS from 'csstype';

function ProductDesign() {

    const imageButtonStyle: CSS.Properties = {
        padding: "10px",
        margin:'10px',
        color:'#222222',
        fontSize:'19px',
        fontFamily:'Muli, sans-serif',
        cursor:'pointer'
    };
    const imageButtonSelectedStyle: CSS.Properties = {
        padding: "10px",
        margin:'10px',
        borderBottom: '3px solid #cb6fa7',
        color:'#222222',
        fontSize:'19px',
        fontFamily:'Muli, sans-serif',
        cursor:'pointer'
    };
    const imgStyle: CSS.Properties = {
        maxWidth: '100%',
        width: '925px',
        margin: '50px 0',
    };
    const shirtImgStyle: CSS.Properties = {
        maxWidth: '90%',
        width: '350px',
        margin: '0 0 50px 0',
    };
    const preloadImg: CSS.Properties = {
        height: '1px',
        width: '1px',
        opacity: '0',
    };
    const [image, setImage] = useState(0);
    return (
        <div className={'ServicesDescriptionPage-content'}>
            <h1>Goal-oriented design</h1>
            <p>We craft beautiful, usable, and efficient interfaces. Our team consists of the finest minds in the UX, UI, and product management fields, each an expert in their own field but maintaining a full understanding of the entire product lifecycle. We design for a goal; our goal is not to design. Having a clear and trustworthy view of our client’s goal increases the accuracy of our proposed design, interactions, and features for the product. </p>
            <h1>Fail fast</h1>
            <p>We believe that the user experience (UX) design is a vital part of the app crafting process. A typical Webiks product design process starts with thorough research of the user needs followed by rapid prototyping design iterations. This iterative approach allows us to “fail fast.” Those “failures” provide us with valuable user feedback and allow us to fine-tuning the design swiftly and accurately. We found this rapid prototyping approach saves our clients time and money by solving common design issues before development begins and puts the development focus on the actual needs of the end user.</p>
            <img src={process.env.PUBLIC_URL + `/assets/images/services/productDesign/webiksShirt.jpg`} style={shirtImgStyle} alt="Design"/>
            <h1>Complex apps made simple</h1>
            <p>We take special pride in our expertise in designing complex applications. We strive for minimalism and simplicity, but we acknowledge that some problems are really complicated. In these cases, applying the standard text-book solutions just won’t do. Tackling complex challenges requires an innovative approach; we encounter each problem with an open mind and the utmost respect.</p>
            <div>
                <span onClick={() => setImage(0)} style={image === 0 ? imageButtonSelectedStyle : imageButtonStyle}>Design</span>
                <span onClick={() => setImage(1)} style={image === 1 ? imageButtonSelectedStyle : imageButtonStyle}>Prototype</span>
                <span onClick={() => setImage(2)} style={image === 2 ? imageButtonSelectedStyle : imageButtonStyle}>Sketching</span>
            </div>
            {image === 0 && <img src={process.env.PUBLIC_URL + `/assets/images/services/productDesign/Design.jpg`} style={imgStyle} alt="Design"/>}
            {image === 1 && <img src={process.env.PUBLIC_URL + `/assets/images/services/productDesign/Prototype.jpg`} style={imgStyle} alt="Prototype"/>}
            {image === 2 && <img src={process.env.PUBLIC_URL + `/assets/images/services/productDesign/Sketching.jpg`} style={imgStyle} alt="Sketching"/>}
            <h1>Non-religious agile</h1>
            <p>Webiks’s product management philosophy is “agile” in nature but not “religious” in fashion. We believe in a flexible, adaptable, and rapid working process, and we acclimate the process to suit the project needs, not the other way around. Our basic principles make sure our people fully understand the client’s business domain, working closely with the development teams and aligning all stakeholders through a transparent and cooperative process.</p>
            <h1>Functional beauty</h1>
            <p>The last stage is to top it all off with a slick and beautiful user interface (UI) graphical design — not just as a shiny touch of paint, but as a thoughtful design that highlights the app’s data hierarchy, structure, and brand with the appropriate visual signifiers. And yeah, it looks really pretty too...</p>


            {/*    preloading images   */}
            <img src={process.env.PUBLIC_URL + `/assets/images/services/productDesign/Prototype.jpg`} style={preloadImg} alt="Prototype"/>
            <img src={process.env.PUBLIC_URL + `/assets/images/services/productDesign/Sketching.jpg`} style={preloadImg} alt="Sketching"/>
            {/*    ////////////////    */}
        </div>
    );
}

export default ProductDesign;
