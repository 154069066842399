import React from 'react';
import './HomePage.css';
import {setPageDetails} from "../../store/views.actions";
import {useDispatch} from "react-redux";
import Mission from "./components/Misson/Mission";
import Services from "../Services/components/Services";
import WhyUs from "./components/WhyUs/WhyUs";
// import Testimonials from "./components/Testimonials/Testimonials";

function HomePage() {
    const dispatch = useDispatch();
    const pageDetails ={
        pageTitle:'Webiks',
        coverImage:null,
        coverTitle:null,
        coverSubTitle:null
    };
    dispatch(setPageDetails(pageDetails));

    return (
        <div className={'Page-HomePage'}>
            <Mission/>
            <h1 className={'Page-HomePage-titles'}><span className={'_strong'}>We only do what we do best,</span> build powerful data-driven intelligent apps.</h1>
            <Services/>
            <h1 className={'Page-HomePage-titles'}>Why work with us?</h1>
            <WhyUs/>
            {/*<Testimonials/>*/}
        </div>
    );
}

export default HomePage;
