import React from 'react';
import WhyUsBox from "./WhyUsBox";
import itemList from "../../../../assets/general/WhyUs.json";

function WhyUs() {

    return (
        <div className={'Page-HomePage-WhyUs'}>
            {
                itemList.map((item,key) => {
                    return (
                        <WhyUsBox img={item.img} title={item.title} content={item.content} key={key}/>
                    );
                })
            }
        </div>
    );
}

export default WhyUs;
