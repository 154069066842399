import React from 'react';
import './Footer.css';
import logoImage from "../../assets/images/logoText.svg";
import SocialBar from "./features/SocialBar/SocialBar";
import {navBarPages} from "../NavBar/NavBar";
import {useHistory} from "react-router-dom";

function Footer() {
    const history = useHistory();
    const year = new Date().getFullYear()
    return (
        <div className={'Footer'}>
            <img className={'Footer-logo'} src={logoImage} alt="logo"/>
            <p className={'Footer-contacts'}>webiks@webiks.com <br/> +972 3 6996617</p>
            <p className={'Footer-copyrights'}>© {year} webiks</p>
            <div className={'Footer-SocialBar'}><SocialBar/></div>
            <div className={'Footer-Nav'}>
                {
                    navBarPages.map((page,key) => {
                            const clickAction = page.page?
                                () => {history.push(`/${page.page}`)}:
                                // @ts-ignore
                                () => {window.open(page.link)};
                            return (
                                <button className={'Footer-link'}
                                        key={key}
                                        onClick={clickAction}
                                >{page.title}</button>
                            );
                        }
                    )}
            </div>
        </div>
    );
}

export default Footer;
