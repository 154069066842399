import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import configureStore from "./store/configureStore";
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from "./theme";

export const store = configureStore();
const reactFunc = window.location.pathname.slice(-4) === 'html' ? ReactDOM.hydrate : ReactDOM.render;
reactFunc(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
