import React from 'react';
import './BottomBar.css';
import {PagesEnum} from "../../definitions/pages-enum";
import ContactButton from "../ContactButton/ContactButton";
import { useLocation } from 'react-router-dom';

function BottomBar() {
    const location = useLocation();
    const active = location.pathname === PagesEnum.HOME;
    const classes = active ? 'BottomBar' : '';
    return (
        <div className={classes}>
            {active && <h1 className={'BottomBar-title'}>Let's work together</h1>}
            {active && <ContactButton/>}
        </div>
    );
}

export default BottomBar;
