import React from 'react';
import './ContactButton.css';
import {PagesEnum} from "../../definitions/pages-enum";
import {useHistory} from "react-router-dom";

function ContactButton() {
    const history = useHistory();
    return (
        <button className={'ContactButton'} onClick={()=>{history.push(`/${PagesEnum.CONTACT}`)}}>
            Contact Us
        </button>
    );
}

export default ContactButton;
