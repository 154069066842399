export interface ViewsActionInterface {
    type: ViewsActionTypes;
    payload: any;
}

export enum ViewsActionTypes {
    setPageDetails ='[Views] set page details',
}

interface PageDetailsInterface {
    pageTitle:string|null,
    coverImage:string|null,
    coverTitle:string|null,
    coverSubTitle:string|null
}

export const setPageDetails = (pageDetails:PageDetailsInterface) => {
    window.scrollTo(0, 0);
    return ({
        type: ViewsActionTypes.setPageDetails,
        payload:pageDetails
    });
};
