import React from 'react';
import './App.css';
import NavBar from "./components/NavBar/NavBar";
import Cover from "./components/Cover/Cover";
import Footer from "./components/Footer/Footer";
import BottomBar from "./components/BottomBar/BottomBar";
import Page from "./pages/Page";

const App: React.FC = () => {
  return (
    <div className="App">
      <NavBar/>
      <Cover/>
      <Page/>
      <BottomBar/>
      <Footer/>
    </div>
  );
};

export default App;
