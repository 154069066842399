import React from 'react';
import './Services.css';
import ServiceBox from "./ServiceBox";
import {ServicesEnum} from "../../../definitions/services-enum";

function Services() {
    return (
        <div className={'Page-ServicesPage-Services'}>
            <div className={'Page-ServicesPage-Services-row'}>
                <ServiceBox img={'service1.svg'} title={'Complex web applications'} link={ServicesEnum.COMPLEX_WEB_APPLICATIONS}
                            content={'We have tremendous experience delivering high-performing, sophisticated, data-driven, long-lasting, production-ready, complex web apps.'}                            />
                <ServiceBox img={'service2.svg'} title={'Geospatial'} link={ServicesEnum.GEOSPATIAL}
                            content={'We focus on the development of geospatial platforms and applications using open-source technology. Our custom-made geospatial solutions help our clients gain location-based insights.'}/>
                <ServiceBox img={'service3.svg'} title={'Product design'} link={ServicesEnum.PRODUCT_DESIGN}
                            content={'Our product team talent lives and breathes data, design, and user experience. Through a methodological process called “design thinking,” they design beautiful and usable data-driven apps. '}/>
                            </div>
            <div className={'Page-ServicesPage-Services-row'}>
                <ServiceBox img={'service4.svg'} title={'Data visualization'} link={ServicesEnum.DATA_VISUALIZATION}
                            content={`For users to transform data into insights, data should be perfectly visualized. Data visualization is not only about beauty, but it’s also about efficiency. We are experts at matching the right visualization method to the data, user, and business goals.`}/>
                <ServiceBox img={'service5.svg'} title={'Data science and machine learning'} link={ServicesEnum.DATA_SCIENCE}
                            content={'From annotating images to training deep learning models, we do whatever it takes to harness the potential of state-of-the-art machine learning techniques for our clients’ data-driven applications. '}/>
                <ServiceBox img={'service6.svg'} title={'Consulting'} link={ServicesEnum.CONSULTING}
                            content={'We consult R&D and product teams that face difficult challenges, such as data engineering, framework migration, performance optimization, large-scale web architecture, geospatial sense-making, and product pivoting.'}/>
                {/*<ServiceBox img={'service7.svg'} title={'Training'} link={ServicesEnum.TRAINING}*/}
                {/*            content={`Angular, React, RxJS, Redux, NgRx, NodeJS, MongoDB, Microservices, Docker-Container, CI/CD, Open Source GIS, and many more. Our instructors are ready to help R&D teams keep pace with technology. We offer public and custom hands-on courses.`}/>*/}
            </div>
        </div>
    );
}

export default Services;
